import SVLandingGridContainer from '@apps/www/src/www/containers/SVLandingGridContainer';
import { sendGTMEvent } from '@next/third-parties/google';
import SVA from '@pkgs/shared-client/components/SVA';
import SVButton, { SVButtonUSES } from '@pkgs/shared-client/components/SVButton';
import SVLandingPageFooter from '@pkgs/shared-client/components/SVLandingPageFooter';
import SVLink from '@pkgs/shared-client/components/SVLink';
import LogoSVG from '@pkgs/shared-client/img/logo-inlined.svg';
import { useMount } from 'react-use';

const SVLandingPage = () => {
	useMount(() => {
		sendGTMEvent('page_home_unlogged');
	});

	return (
		<div className="flex justify-between w-full min-h-screen theme-dark">
			<SVLandingGridContainer />
			<div className="box-border relative flex flex-col flex-grow">
				<div className="box-border relative flex flex-col justify-between flex-grow p-10 space-y-20 -smp:px-5">
					<div className="flex justify-center">
						<LogoSVG className="text-primary h-11 w-11" />
					</div>
					<div className="flex flex-col justify-center text-center">
						<h1 className="mb-6 type-huge -smp:mb-4">Made for Creatives</h1>
						<p className="inline-block mb-16 type-subtitle -smp:mb-8">
							Browse and save curated inspiration from all over the&nbsp;world.
						</p>
						<div className="flex space-x-6 flex-center">
							<SVA Component={SVLink} to="/login/" title="Log in">
								Log in
							</SVA>
							<SVButton
								use={SVButtonUSES.PRIMARY}
								Component={SVLink}
								to="/join/"
								title="Join"
							>
								Join
							</SVButton>
						</div>
					</div>
					<SVLandingPageFooter />
				</div>
			</div>
		</div>
	);
};

export default SVLandingPage;
